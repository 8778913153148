@import "../../shared/styles/base";
@import '../../../node_modules/ngx-toastr/toastr-bs4-alert';
@import "../../shared/styles/custom/pl-button";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../shared/styles/custom/signatureFontFaces";

html, body {
  min-width: 320px;
  --focus-shadow: #{$input-focus-box-shadow};
  --btn-focus-shadow: #{$btn-focus-box-shadow};
}

* {
  scroll-behavior: smooth;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

p {
  margin: 0;
}

app-page .btn {
  display: unset;
}

.ng2-pdf-viewer-container .pdfViewer {
  padding-top: map-get($spacers, 3);

  .page {
    @extend .elevation-2;
  }
}

//.custom-checkbox, .custom-radio {
//  position: relative;
//
//  &:has(:focus-visible):before {
//    content: "";
//    position: absolute;
//    top: 2px;
//    bottom: 2px;
//    left: -1px;
//    right: -1px;
//    border-radius: 1px;
//  }
//
//  // Firefox sadly doesn't support the :has pseudo-selector yet. So here's the fallback for that.
//  @supports not selector(:has(:focus-visible):before) {
//    &:focus-within:before {
//      content: "";
//      position: absolute;
//      top: 2px;
//      bottom: 2px;
//      left: -1px;
//      right: -1px;
//      border-radius: 1px;
//    }
//  }
//}

.gizmo-destination {
  &.is-current-target {
    transform: scale(1);
    backface-visibility: hidden;
    perspective: 1000px;

    &:focus.is-invalid {
      box-shadow: 0 0 0 $input-focus-width transparentize($danger, .75) !important;
      border-color: lighten($danger, 15%) !important;
    }

    &:focus.is-valid {
      box-shadow: 0 0 0 $input-focus-width transparentize($green, .75) !important;
      border-color: lighten($green, 15%) !important;
    }

    &:focus:not(.is-invalid):not(.is-valid) {
      box-shadow: 0 0 0 $input-focus-width transparentize($input-focus-border-color, .75) !important;
    }
  }
}

// navbar at the bottom forces toasts 60px up (plus 12px distance)
@media (max-width: map-get($grid-breakpoints, sm)) {
  .toast-bottom-right {
    bottom: 72px;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  .modal-md {
    max-width: 520px;
  }
}

