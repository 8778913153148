@font-face {
  font-family: 'Fallind';
  src: url("/assets/fonts/Fallind.ttf");
}

@font-face {
  font-family: 'grundschrift-beta';
  src: url("/assets/fonts/grundschrift-beta.ttf");
}

@font-face {
  font-family: 'BeliyaSignature';
  src: url("/assets/fonts/BeliyaSignature.ttf");
}

@font-face {
  font-family: 'IndieFlower-Regular';
  src: url("/assets/fonts/IndieFlower-Regular.ttf");
}
